.GrLocation
{
color: #00AFEF;
}
.directframe {
  padding: 0 5% 5%;
}
.main_contact_inner .anyhead {
  width: 33%;
  display: inline-block;
  vertical-align: top;
  padding-left: 50px;
  padding-top: 50px;
}
.contact-form {
  width: 63%;
  display: inline-block;
  vertical-align: top;
}
.contact-inputs{
    display: flex;
    flex-direction: column;
    gap: 20rem;
}
.name{
    width: 100%;
}
.leblname{
    color: #5C5F63;
    font-family: "Poppins",Helvetica,sans-serif;
}
.anyheading{
    padding: 15px;
    font-family: "Poppins",Helvetica,sans-serif;
    margin-left: 10%;
    font-weight: bold;
}

.btnsend{
    text-align:center;
  }
  .sendmsg {
    font-family: "Poppins",Helvetica,sans-serif;
    border: 2px solid #00AFEF;
    padding: 14px 48px;
    display: inline-block;
    text-decoration: none;
    font-size: 14px;
    margin: 20px;
    border-radius: 30px;
    color: black;
    transition: all 0.5s ease-in-out;
    background-color: transparent;
}
  .sendmsg:hover{
    color:white;
    background-color: #00AFEF;
  }
  .main_contact_inner {
    display: block;
    width: 100%;
}
.main_contact_inner {
  display: block;
  width: 100%;
}
.contact-form form.contactinput {
  display: block;
  width: 100%;
}
.contact-form form.contactinput .contact_column {
  width: 50%;
  display: inline-block;
  vertical-align: bottom;
}
.contact-form form.contactinput .contact_column:first-child{
  padding-right: 30px;
}
.contact_infomation h6 {
  font-size: 16px;
  color: #333;
  font-weight: 600;
  font-style: normal;
  margin: 7px 0;
}
.contact_infomation p {
  font-size: .92em;
  font-style: normal;
  line-height: 1.75em;
  color: #666;
  margin: 0 0 10px;
}
.contact-form form.contactinput .contact_column input.name {
  position: relative;
  border: 2px solid #bfbfbf;
  border-radius: 5px;
  display: block;
  font-size: 1em;
  margin: 0;
  padding: 10px 25px 10px 12px;
  width: 100%;
  min-width: 100%;
  background: 0 0;
  text-align: left;
  color: inherit;
  box-shadow: none;
  outline: none;
}
.contact-form form.contactinput .contact_column textarea.name {
  position: relative;
  border: 2px solid #bfbfbf;
  border-radius: 5px;
  display: block;
  font-size: 1em;
  margin: 0;
  padding: 10px 25px 10px 12px;
  width: 100%;
  min-width: 100%;
  background: 0 0;
  text-align: left;
  color: inherit;
  box-shadow: none;
  outline: none;
}
.main_contact_inner {
  display: block;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}
.contact_infomation .contact_icon {
  width: 10%;
  display: inline-block;
  vertical-align: top;
  padding-top: 8px;
}
.contact_infomation .contect_info {
  width: 90%;
  display: inline-block;
}
.contact_infomation .contact_icon svg {
  position: relative;
  display: table-cell;
  font-size: 33px;
  color: #00AFEF;
}
.contact_infomation .contact_icon svg path {
  fill: #00AFEF;
}
.contact_infomation {
  display: block;
  vertical-align: top;
  padding-bottom: 24px;
}