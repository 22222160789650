/* HEADER CSS LAYOUT PAGE START */
.header_inner {
  width: 100%;
  display: block;
  padding: 30px;
  position: relative;
  z-index: 9;
}
  .header_inner  .logo {
    display: inline-block;
    width: 50%;
  }
  .header_inner nav.navlayout {
    width: 50%;
    display: inline-block;
    float:right;
  }
  .header_inner nav.navlayout ul.ullayout {
    padding-top: 15px;
    float: right;
  }
  .header_inner nav.navlayout ul.ullayout  li.layoutli {
    list-style-type: none;
    font-size: 16px;
    padding: 7px 20px;
    margin-right: 6px;
  }
  li.layoutlidrop {
    width: auto;
    padding: 5px 10px 5px 10px;
  }
  /* HEADER CSS LAYOUT PAGE END */
  .ullayout {
    display: flex;
}
.drop:hover {
    display: block;
}
.layoutli a {
    text-decoration: none;
    color: #232D38;
}
.droplayout {
    display: none;
}
li.layoutli:hover ul.droplayout {
    display: block;
}
li.layoutli ul.droplayout {
    list-style: none;  
    background-color: #fcfcfc;
    border-color: #eee;
    padding: 10px;
    position: absolute;
    border-radius: 10px;
}
li.layoutli ul.droplayout li.layoutlidrop a{
    background-color: #fff;
    left: -23px;
    top: 100px;
    width: auto;
    max-width: none;
    text-decoration: none;
    transition: all 0.5s ease-in-out;
}
