.resume_header_inner {
  display: block;
  position: relative;
  background-color: #fdfdfd;
  border-top: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
  padding: 65px 70px;
  margin-bottom: 65px;
  text-align: left;
  width: 100%;
}
.resume_header_inner .page-title h1 {
  color: #333;
  font-size: 44px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0;
  line-height: 1.2;
}
.resume_header_inner .page-title .page-subtitle {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 70px;
}
.resume_header_inner .page-title .page-subtitle h4 {
  color: #aaa;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0;
  margin: 1.33em 0;
}
.main_resume {
  display: block;
  width: 100%;
  padding: 50px 75px;
}
.education_resume_inner {
  display: block;
  width: 100%;
}
.education_resume_inner .education_resume_col {
  width: 50%;
  display: inline-block;
  position: relative;
}
.education_resume_inner .education_resume_col:before {
  content: '';
  position: absolute;
  display: block;
  height: 100%;
  background-color: #f5f6f9;
  width: 1px;
  left: 0;
  bottom: 5px;
}
.education_resume_inner .education_resume_col:after {
  content: '';
  position: absolute;
  display: block;
  height: 1px;
  background-color: #f5f6f9;
  width: calc(100% - 15px);
  left: 0px;
  bottom: 3px;
}
.education_resume_inner .education_resume_col {
  width: 100%;
  display: inline-block;
  position: relative;
  padding: 25px 0 20px 40px;
  padding-top: 3px;
}
.education_column_resume {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  position: relative;
}
.education_resume_inner .education_resume_col h5.item-period {
  display: inline-block;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  margin: 0 0px 5px -65px;
  color: #666;
  background-color: #fff;
  padding: 0 10px;
  line-height: 23px;
  border: 2px solid #00afef;
  border-radius: 30px;
}
.education_resume_inner .education_resume_col span.eduction_title {
  display: inline-block;
  font-size: 12px;
  color: #666;
  opacity: .7;
  margin-left: 5px;
  padding-left: 10px;
}
.education_resume_inner .education_resume_col h6.item-title{
  font-size: 16px;
  margin: 10px 0 7px;
  color: #333;
}
.education_resume_inner .education_resume_col p.Text {
  font-size: .92em;
  font-style: normal;
  color: #666;
  font-weight: 400;
}
.certificates_inner {
  display: block;
  width: 100%;
  padding: 50px 0;
}
.certificates_row {
  display: block;
  width: 100%;
}
.certificates_row .certificates_col {
  width: 50%;
  display: inline-block;
  padding: 0 15px;
}
.certificates_row .certificates_col .certificates-column_cart{
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  border: 2px solid #e5e5e5;
  border-radius: 8px;
  margin-bottom: 20px;
  -webkit-transition: all 2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
.certi-logo {
  display: table-cell;
  width: 120px;
  height: 100%;
  background-color: #f5f5f5;
  padding: 25px;
  vertical-align: middle;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.certigicates_info {
  display: table-cell;
  width: 100%;
  padding: 48px 25px;
  vertical-align: middle;
}
.certigicates_info h4 {
  font-size: 16px;
  margin: 0 0 5px;
  font-weight: 700;
  font-style: normal;
  color: #333;
}
.membership_id {
  font-size: .9em;
  color: #777;
  line-height: 1.5;
  font-style: normal;
  text-align: left;
}
.membership_date {
  font-size: .7em;
  color: #777;
  opacity: .7;
}
.certificates_row .certificates_col:first-child {
  padding-left: 0px;
}
.certificates_row .certificates_col:last-child {
  padding-left: 0px;
}
.main_skillsdesign {
  display: block;
  width: 100%;
  padding: 50px 0;
}
.main_skillsdesign_row {
  width: 100%;
  display: block;
}
.main_skillsdesign_row .main_skillsdesign_col {
  width: 50%;
  display: inline-block;
  padding: 0 20px;
  position: relative;
}
.main_skillsdesign_row .main_skillsdesign_col h6 {
  font-size: 13px;
  line-height: 1.1em;
  position: relative;
  margin: 0 0 4px;
  color: #333;
  font-weight: 600;
  font-style: normal;
}
.main_skillsdesign_row .main_skillsdesign_col .progress-bar {
  background-color: transparent;
  height: 6px;
  margin: 1px;
  border-radius: 12px;
  border: 1px solid #ccc;
  padding: 6px 1px;
} 
.main_skillsdesign_row .main_skillsdesign_col .progress {
  background-color: transparent;
  border: 2px solid #e5e5e5;
  height: 12px;
}
.main_skillsdesign_row .main_skillsdesign_col:nth-child(odd) {
  padding-left: 0;
}
.main_skillsdesign_row .main_skillsdesign_col:nth-child(even) {
  padding-right: 0;
}
.certificates_row .certificates_col .certificates-column_cart:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 12px 17px rgba(0,0,0,.12);
  -moz-box-shadow: 0 12px 17px rgba(0,0,0,.12);
  -o-box-shadow: 0 12px 17px rgba(0,0,0,.12);
  box-shadow: 0 12px 17px rgba(0,0,0,.12);
}
.certificates_headin {
  display: inline-block;
  width: 50%;
}
.hireme{
  text-align: center;
}
.hiremehead a{
  text-decoration: none;
  color:aquamarine;
}
span.percentage {
  position: absolute;
  right: 20px;
  top: 0px;
  font-size: 11px;
  line-height: 1.1em;
  float: right;
  margin: 0 0 4px;
  color: #888;
}
.main_skillsdesign_row .main_skillsdesign_col:nth-child(even) span.percentage {
  right: 0px;
}
.certificates_headin:nth-child(2) {
  padding-left: 20px;
}
span.ests_website {
  display: block;
}
span.ests_website a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
}
h2.rect-progress-bar-percent {
  position: absolute;
  right: -8px;
  top: -12px;
  color: #888;
  float: right;
  font-size: 11px !important;
  line-height: 1.1em;
}
hr.eduction_link {
  position: absolute;
  width: 1.5px;
  height: 150px;
  background-color: #393939;
  transform: rotate(90deg);
  left: 0;
}
hr.eduction_link.two , hr.eduction_link.three, hr.eduction_link.one{
  top: -51px;
}
hr.eduction_link.four {
  top: -25px;
  height: 110px;
  left: -19px;
}

.certigiclink a{
  text-decoration: none;
  color:black;
}
.inner-rect-bar.addRectAnimate {
  background-color: #00AFEF !important;
}
hr.exprience_link {
  background-color: #393939;
  position: absolute;
}
hr.exprience_link {
  bottom: 0;
  height: 64%;
  left: -75px;
  top: 92px;
  width: 2px;
  z-index: 9;
}
hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: .25;
}