.galleryItem img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
}
.galleryContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}
.galleryWrapper {
    padding: 50px 10% 100px;
}
.filterItem ul {
    display: felx;
    align-items: center;
    list-style: none;
    padding: 0;
    gap: 20px;
    margin-bottom: 30px;
}
button:hover {
    background: #222;
    cursor: pointer;

}
.filterItem ul {
    display: flex;
    justify-content: start;
}
.filterItem ul li button {
    background: transparent;
    border: unset;
    color: #222;
    font-size: 20px;
    line-height: 1.5;
    padding: 3px 14px;
    text-decoration: none;
    opacity: .5;
}
.resumehead_inner {
    display: block;
    position: relative;
    background-color: #fdfdfd;
    border-top: 2px solid #f2f2f2;
    border-bottom: 2px solid #f2f2f2;
    padding: 65px 70px;
    margin-bottom: 65px;
    text-align: left;
}
.resumehead_inner h1.headResume {
    color: #333;
    font-size: 44px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0;
}