.logo-symbol {
  float: left;
  width: 44px;
  height: 44px;
  line-height: 44px;
  background-color: #00AFEF;
  border-radius: 100px;
  text-align: center;
  color: #fff;
  font-size: 28px;
  font-weight: 800;
  margin-right: 8px;
  margin-top: 4px;
}

.logo-text span {
  font-weight: 400;
}

.logo-text {
  float: left;
  line-height: 50px;
  font-size: 20px;
  color: #222;
  font-weight: 600;
}

img {
  width: 100%;
}

.banner_innner {
  width: 100%;
  display: block;
  padding: 50px 0;
}

.banner_innner .banner_imges {
  width: 49%;
  display: inline-block;
}

.banner_innner .banner_info {
  width: 49%;
  display: inline-block;
  vertical-align: top;
}

.banner_inner_row {
  display: flex;
  justify-content: end;
  align-items: center;
}

.banner_innner .banner_imges img {
  width: 410px;
  height: 410px;
  border-radius: 100%;
  border: 20px solid #fff;
  box-shadow: 0 0 6px 2px #ccc;
}

.banner_info_col h3.titleStyle {
  font-size: 48px;
  line-height: 56px;
  margin-top: 0;
  margin-bottom: 20px;
  color: #333;
  font-weight: 600;
}

.banner_info_col p.headerFont {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.75em;
  color: #666;
}

.banner_info_col {
  padding-right: 170px;
  padding-top: 0px;
  padding-left: 38px;
}

.banner_info_col .button a.downloadcv {
  display: inline-block;
  position: relative;
  padding: .8em 2.1em;
  margin-bottom: .75em;
  margin-right: .25em;
  font-size: 1em;
  line-height: 1.2;
  outline: 0;
  border: 2px solid #00AFEF;
  color: #222;
  text-shadow: none;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 10px 10px -8px rgba(0, 0, 0, .22);
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

.banner_info_col .button a.contact {
  display: inline-block;
  position: relative;
  padding: .8em 2.1em;
  margin-bottom: .75em;
  margin-right: .25em;
  font-size: 1em;
  line-height: 1.2;
  outline: 0;
  border: 2px solid;
  text-shadow: none;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  border-color: #d5d5d5;
  background-color: #fff;
  box-shadow: 0 10px 10px -8px #d5d5d5;
  color: #666;
  text-decoration: none;
}

.main_whatido_innerr {
  width: 100%;
  display: block;
  padding: 50px 0;
}

h3.block-title {
  display: inline-block;
  position: relative;
  font-size: 21px;
  margin: 0 0 30px;
  z-index: 1;
  padding-bottom: 7px;
  color: #333;
  font-weight: 600;
  font-style: normal;
}

h3.block-title:before {
  display: block;
  position: absolute;
  content: '';
  width: 100%;
  background-color: #f5f5f5;
  height: 2px;
  bottom: 0;
}

h3.block-title:after {
  display: block;
  position: absolute;
  content: '';
  width: 30px;
  background-color: #00AFEF;
  height: 2px;
  bottom: 0;
}

.main_whatido_innerr {
  width: 100%;
  display: block;
  padding: 50px 100px;
  box-sizing: border-box;
}

.about_whataredo_info .about_whataredo_info_colum {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

.whatido_infomation .icon {
  display: inline-block;
  width: 10%;
  vertical-align: top;
}

.whatido_infomation .what_i_do_detail {
  display: inline-block;
  width: 90%;
}

.whatido_infomation .icon svg {
  position: relative;
  display: table-cell;
  font-size: 33px;
}

.whatido_infomation .icon svg path {
  fill: #00AFEF;
}

.whatido_infomation .what_i_do_detail h6.whaterheading {
  font-size: 16px;
  color: #333;
  font-weight: 600;
  font-style: normal;
}

.whatido_infomation .what_i_do_detail p {
  font-style: normal;
  line-height: 1.75em;
  color: #666;
  margin: 0 0 10px;
  font-size: .92em;
}

.testimonials {
  width: 100%;
  display: block;
  padding: 50px 100px;
  box-sizing: border-box;
}

.testimonial_main-inner {
  width: 100%;
  display: block;
}

.testimonial_main-inner .testimonial_column {
  position: relative;
}

.testimonial_main-inner .testimonial_column img.testimonial-img {
  position: absolute;
  display: block;
  top: -30px;
  left: -30px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  z-index: 9;
}

.testimonial_main-inner .slick-list {
  width: 100%;
  display: block;
  padding: 35px;
}

.testimonial_main-inner .testimonial_column .testimonial_info {
  position: relative;
  padding: 30px 20px 20px 60px;
  background-color: #fff;
  border-radius: 8px;
  border: 2px solid #e5e5e5;
  -webkit-transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  -ms-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}

.testimonial_main-inner .slick-list .slick-slide {
  padding: 40px;
  box-sizing: border-box;
}

.testimonial_main-inner .testimonial_column .testimonial_info p {
  font-style: italic;
  font-size: 14px;
  line-height: 1.75em;
  color: #666;
  margin: 0 0 10px;
}

.testimonial_main-inner .testimonial_column .testimonial_info h5 {
  margin: 0 0 2px;
  font-weight: 600;
  font-style: normal;
  color: #333;
  font-size: .9em;
}

.testimonial_main-inner .testimonial_column .testimonial_info h6 {
  font-style: normal;
  line-height: 1.75em;
  margin: 0;
  font-size: .8em;
  color: #bbb;
}

.main_client_inner {
  width: 100%;
  display: block;
  padding: 50px 100px;
}

.main_client_inner .client_column img {
  object-fit: contain;
  width: 100%;
  height: 160px !important;
}

.main_funfacts {
  width: 100%;
  display: block;
  padding: 50px 100px;
}

.fun_fact_inner_row {
  display: block;
  width: 100%;
}

.fun_fact_inner_row .fun_fact_inner_column {
  width: 25%;
  display: inline-block;
}

.fun_fact_inner_row .fun_fact_inner_column .funfacts-column_cart {
  position: relative;
  text-align: center;
  width: 100%;
  display: block;
  margin: 0 0 15px;
  background: #fff;
  padding: 25px 10px 15px;
  border: 2px solid #e5e5e5;
  border-radius: 10px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.fun_fact_inner_row .fun_fact_inner_column .funfacts-column_cart:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 12px 17px rgba(0, 0, 0, .12);
  -moz-box-shadow: 0 12px 17px rgba(0, 0, 0, .12);
  -o-box-shadow: 0 12px 17px rgba(0, 0, 0, .12);
  box-shadow: 0 12px 17px rgba(0, 0, 0, .12);
}

.fun_fact_inner_row .fun_fact_inner_column {
  width: 25%;
  display: inline-block;
  padding: 0 10px 0;
}

.fun_fact_inner_row .fun_fact_inner_column:first-child {
  padding-left: 0;
}

.fun_fact_inner_row .fun_fact_inner_column:last-child {
  padding-right: 0;
}

.banner_info_col .button a.downloadcv:hover {
  color: white;
  background-color: #00AFEF;
}

.banner_info_col .button a.contact:hover {
  background-color: #dddd;
  color: black;
}

.fun_fact_inner_row .fun_fact_inner_column .funfacts-column_cart svg {
  font-size: 33px;
  color: #00AFEF;
  margin-bottom: 6px;
  width: 33px;
  height: 33px;
}

.fun_fact_inner_row .fun_fact_inner_column .funfacts-column_cart p {
  font-size: 16px;
  margin: 15px;
  color: #333;
  font-weight: 700;
}

.fun_fact_inner_row .fun_fact_inner_column .funfacts-column_cart span.lm-info-block-value {
  color: #d5d5d5;
  font-size: 48px;
  line-height: 52px;
  display: block;
  margin: 15px 0 10px;
}
@media only screen and (min-width: 1400px) {
  .page_wrapper {
    max-width: 120rem;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .header_inner {
    max-width: 120rem;
    margin: 0 auto;
  }
}
@media (max-width: 1200px) {
  .banner_innner .banner_imges {
      width: 100%;
      display: block;
  }
  .banner_inner_row {
      justify-content: center;
  }
  .banner_innner .banner_info {
      width: 100%;
      display: block;
  }
  .banner_info_col {
      padding-right: 20px;
      padding-left: 20px;
  }
  .page_wrapper {
      display: block;
      width: 100%;
  }
}