.footer{
  display: flex;
}

.site-footer{
  background-color: #fcfcfc;
  border-color: #eee;
  
  }
.footerul{
display: flex;
list-style-type: none;
}
.lifooter 
{
  margin: 10px;
} 
.lifooter a{
  text-decoration: none;
  color: black;
}
.footer-copyrights {
  margin-top: 1%;
    margin-left: 63%;
}
.footerp{
font-family: "Poppins",Helvetica,sans-serif;
font-size: 14px;
}








.footer-social-links li a {
  color: #333;
text-decoration: none;
    line-height: 21px;
    font-size: 13px;
    opacity: .6;
    font-family: "Poppins",Helvetica,sans-serif;
    font-size: 14px;
    font-weight: 0;
    font-style: normal;
    line-height: 1.75em;
    color: #666;
}
.footerp a{
  text-decoration: none;
  color:black;
}